<template>
  <div class="trade-now">
    <div class="section section-1">
      <b-container>
        <b-row>
          <b-col cols="12" md="12" class="content-wyb pr-2 pl-3">
            <h2 class="title">Trade Now</h2>
            <p>Trade now with ESB FX to get all opportunities from the market:</p>
            <div class="box-market">
              <div class="box-item">
                <div class="icon">
                  <img src="~@/assets/images/icons/wyb/2.png" alt="" />
                </div>
                <div class="content">Low cost</div>
              </div>
              <div class="box-item">
                <div class="icon">
                  <img src="~@/assets/images/icons/wyb/1.png" alt="" />
                </div>
                <div class="content">Various forms of deposit</div>
              </div>
              <div class="box-item">
                <div class="icon">
                  <img src="~@/assets/images/icons/wyb/3.png" alt="" />
                </div>
                <div class="content">
                  Freedom to choose the type of account that suits each individual.
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="section section-2">
      <b-container>
        <b-row>
          <b-col cols="12">
            <h2 class="title">Integrate the optimal tool store</h2>
            <p class="mini-title text-center">
              Trade with 800+ available assets - get all opportunities from the market
            </p>
          </b-col>
        </b-row>
        <b-row class="pt-2 justify-content-center">
          <b-col cols="12" class="list-wyb">
            <div class="box-wyb">
              <div class="icon">
                <img src="~@/assets/images/icons/wyb/1.png" alt="" />
              </div>
              <div class="title">
                <h3>Forex</h3>
              </div>
              <div class="content">
                <p>
                  Access to the most liquid market in the world. Trade major, minor and exotic
                  currency pairs with low commissions and competitive spreads.
                </p>
              </div>
            </div>
            <div class="box-wyb">
              <div class="icon">
                <img src="~@/assets/images/icons/wyb/2.png" alt="" />
              </div>
              <div class="title">
                <h3>Index difference contract</h3>
              </div>
              <div class="content">
                <p>
                  Trade on the volatility of 14 major stock markets worldwide with no commissions
                </p>
              </div>
            </div>
            <div class="box-wyb">
              <div class="icon">
                <img src="~@/assets/images/icons/wyb/3.png" alt="" />
              </div>
              <div class="title">
                <h3>Commodities</h3>
              </div>
              <div class="content">
                <p>
                  Trade commodity futures such as coffee, cocoa, cotton, orange juice, and sugar
                  with the option to trade on USDX
                </p>
              </div>
            </div>
            <div class="box-wyb">
              <div class="icon">
                <img src="~@/assets/images/icons/wyb/4.png" alt="" />
              </div>
              <div class="title">
                <h3>Cryptocurrency</h3>
              </div>
              <div class="content">
                <p>
                  Buy and Sell CFDs on 5 kinds of cryptocurrency with up to 20:1 leverage without a
                  wallet or exchange.
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="section section-3">
      <b-container>
        <b-row class="">
          <b-col cols="12" md="6" class="col-img">
            <img src="~@/assets/images/background/s3-traden.jpeg" alt="" />
          </b-col>
          <b-col cols="12" md="6" class="box-tr-d">
            <h2 class="title">Swaps, spreads, and commissions</h2>
            <p class="mini-title">
              Enjoy transparent, competitive trading costs from 5 USD per spin with no account fees.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="section section-4">
      <b-container>
        <b-row>
          <b-col cols="12">
            <h2 class="title">The forex account at ESB FX</h2>
            <p class="mini-title">
              ESB FX has all types of trading accounts to suit all your trading needs and
              styles.
            </p>
          </b-col>
        </b-row>
        <b-row class="pt-2 pt-lg-2 justify-content-center">
          <b-col cols="12" class="list-wyb">
            <div class="box-wyb">
              <div class="title">
                <h3>Standard</h3>
              </div>
              <div class="content">
                <p>
                  Competitive spreads No commissions. The ideal choice for beginners and long-term
                  strategy traders.
                </p>
              </div>
            </div>
            <div class="box-wyb">
              <div class="title">
                <h3>ECN</h3>
              </div>
              <div class="content">
                <p>
                  Users enjoy institutional spreads from 0.0 pips at just 5 USD per lot. Perfect
                  choice for scalpers or algorithmic traders.
                </p>
              </div>
            </div>
          </b-col>
          <b-col cols="12" class="text-center">
            <a href="#" class="btn btn-success mt-4">
              Learn more about the types of trading accounts
            </a>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="section section-5">
      <b-container>
        <b-row>
          <b-col cols="12" md="6" class="p-0 bg-od">
            <div class="box-d-w">
              <div class="title">Deposit - withdraw conveniently & quickly</div>
              <div class="content">
                Fast and convenient deposit and withdrawal operations without geographical and time
                limits. Activities related to user assets take place in a secure space, ensuring
                maximum safety for users.
              </div>
              <div class="button-div">
                <a href="#" class="btn btn-success"> Learn more </a>
              </div>
            </div>
          </b-col>
          <b-col cols="12" md="6" class="p-0">
            <img src="~@/assets/images/background/start.jpeg" alt="" />
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="section section-6">
      <b-container>
        <b-row>
          <b-col cols="12" md="6" class="p-0">
            <img src="~@/assets/images/background/start.jpeg" alt="" />
          </b-col>
          <b-col cols="12" md="6" class="p-0 bg-od">
            <div class="box-d-w">
              <div class="title">How to get started?</div>
              <div class="content">Let's start by learning the steps in a trading journey</div>
              <div class="button-div">
                <a href="#" class="btn btn-outline-success"> Learn more </a>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="section section-7">
      <Started />
    </div>
  </div>
</template>

<script>
import Started from '@/components/shared/Started.vue';

export default {
  components: { Started },
};
</script>

<style lang="scss">
.trade-now {
  h2.title {
    font-size: clamp(1.2rem, 3vw, 2.2rem);
  }
  .section-1 {
    background: url('~@/assets/images/background/1.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.8);
    box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.8);
    background-attachment: fixed;
    padding: 150px 0 55px;
    min-height: 30vh;
    position: relative;
    .content-wyb {
      color: #fff;
      padding: 0 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      p {
        font-weight: 400;
        letter-spacing: 0.3px;
        line-height: 110%;
        margin-bottom: 5px;
        text-align: center;

        &.dxt {
          padding-left: 15px;
          color: #88c24b;
          text-transform: capitalize;
          span {
            font-weight: 800;
          }
        }
      }
      .box-market {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        .box-item {
          width: 175px;
          height: 175px;
          background: #fff;
          border-radius: 10px;
          box-sizing: border-box;
          padding: 10px 5px;
          position: relative;
          background-color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          margin: 1.2rem 10px;
          border: none;
          border-top: 5px solid #0d9881;
          border-bottom: 5px solid #88c24b;
          z-index: 10;
          box-shadow: 0 4px 20px 0 rga(0, 0, 0, 0.08);
          .icon {
            width: 2.5rem;
            display: flex;
            align-items: flex-end;
            margin: 0 auto 1rem;
            img {
              color: #88c24b;
              width: 100%;
              height: auto;
            }
          }
          .content {
            text-align: center;
            color: #333;
            font-size: clamp(15px, 3vw, 15px);
            font-weight: 700;
            line-height: 120%;
            margin-bottom: 15px;
            letter-spacing: 0.7px;
            position: relative;
            z-index: 2;
            transition: all 0.3s ease 0s;
            text-align: center;
            text-transform: capitalize;
          }
        }
      }
    }
    .content-img-wyb-1 {
      width: 100%;
      img {
        // max-height: 385px;
        height: 100%;
        width: 100%;
        border-radius: 5px;
      }
    }

    @media screen and (min-width: 1441px) {
      .content-wyb {
        p {
          font-size: 1.125rem;
          line-height: 150%;
        }
      }
    }
    @media (max-width: 1440px) {
      padding: 130px 0 25px;
      .content-wyb {
        .box-market {
          .box-item {
            height: 135px;
            .icon {
              width: 2rem;
            }
            .content {
              font-size: clamp(12px, 3vw, 13px);
            }
          }
        }
      }
    }
    @media (max-width: 767px) {
      padding: 125px 0 55px;
      min-height: 300px;
      .content-img-wyb-1 {
        display: none;
      }
      .content-wyb {
        text-align: center;
        padding: 20px 15px;
        p {
          margin: auto;
          text-align: center;
        }
      }
    }
  }
  .section-2 {
    min-height: 215px;
    overflow: hidden;
    padding-top: 90px;
    padding-bottom: 90px;
    h2.title {
      font-size: clamp(1.2rem, 3vw, 1.9rem);
      max-width: 821px;
      text-align: center;
      margin: auto;
    }
    .mini-title {
      margin-top: 5px;
    }
    .list-wyb {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    .box-wyb {
      border-radius: 16px;
      box-sizing: border-box;
      position: relative;
      background-color: #fff;
      padding: 15px;
      min-height: 310px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin: 1.2rem 10px;
      width: 240px;
      border: none;
      border-top: 3px solid #0d9881;
      border-bottom: 2px solid #88c24b;
      z-index: 10;
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.08);

      .icon {
        height: 2.5rem;
        margin-bottom: 1rem;
        width: 2.5rem;
        display: flex;
        align-items: flex-end;
        img {
          color: #88c24b;
          width: 100%;
          height: auto;
        }
      }
      h3 {
        color: #333;
        font-size: clamp(15px, 3vw, 19px);
        font-weight: 600;
        line-height: 120%;
        margin-bottom: 15px;
        letter-spacing: 0.7px;
        position: relative;
        z-index: 2;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        text-align: center;
      }
      p {
        font-weight: 400;
        font-size: 15px;
        color: #555;
        letter-spacing: 0.88px;
        line-height: 26px;
        position: relative;
        z-index: 2;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        text-align: center;
      }
      &:hover {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2);
      }
    }
    @media (max-width: 1440px) {
      padding: 30px 0 25px;
    }
    @media (max-width: 991px) {
      padding: 30px 0 20px;
    }
  }
  .section-3 {
    overflow: hidden;
    padding: 0;
    background-color: #f2f4f6;
    background: url('~@/assets/images/background/3.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    -webkit-box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.9);
    box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.9);
    img {
      width: 100%;
      height: 320px;
      object-fit: cover;
    }
    .box-tr-d {
      padding: 0 2rem;
      color: #fff;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      h2 {
        margin-bottom: 40px;
      }

      .mini-title,
      h2 {
        text-align: left;
        text-align-last: left;
      }
      @media (max-width: 767px) {
        .mini-title,
        h2 {
          text-align: center;
          text-align-last: center;
          margin: 20px auto 15px;
        }
        .mini-title {
          font-size: 16px;
          margin-bottom: 25px;
        }
      }
      @media (max-width: 576px) {
        padding: 0 15px;
        .col-img {
          padding: 0;
        }
        h2.title {
          font-size: clamp(1.4rem, 3vw, 2rem);
          width: 100%;
        }
        .mini-title {
          margin: 0 auto 25px;
          font-size: 1rem;
          width: 100%;
        }
      }
    }
    @media (max-width: 576px) {
      .col-img {
        padding: 0;
      }
    }
  }
  .section-4 {
    min-height: 215px;
    overflow: hidden;
    padding-top: 90px;
    padding-bottom: 90px;
    background-color: #f2f4f6;
    h2.title {
      font-size: clamp(19px, 3.5vw, 23px);
      max-width: 821px;
      text-align: center;
      margin: auto;
      text-transform: capitalize;
    }
    .mini-title {
      font-size: clamp(15px, 3vw, 17px);
      max-width: 821px;
      text-align: center;
      margin: 15px auto;
    }
    .list-wyb {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    .box-wyb {
      border-radius: 16px;
      box-sizing: border-box;
      position: relative;
      background-color: #fff;
      height: 275px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin: 1.2rem 10px;
      width: 230px;
      border: none;
      z-index: 10;
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.08);
      .title {
        background-image: url(~@/assets/images/background/overlay.png);
        padding: 15px 0;
        border-radius: 16px 16px 0 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 65px;
        h3 {
          color: #fff;
          font-size: clamp(15px, 3vw, 20px);
          font-weight: 600;
          line-height: 120%;
          letter-spacing: 0.7px;
          position: relative;
          z-index: 2;
          -webkit-transition: all 0.3s ease 0s;
          -moz-transition: all 0.3s ease 0s;
          -o-transition: all 0.3s ease 0s;
          transition: all 0.3s ease 0s;
          text-align: center;
        }
      }
      .content {
        padding: 15px;
        display: flex;
        align-items: center;
        height: 210px;
        p {
          font-weight: 400;
          font-size: 17px;
          color: #555;
          letter-spacing: 0.88px;
          line-height: 26px;
          position: relative;
          z-index: 2;
          -webkit-transition: all 0.3s ease 0s;
          -moz-transition: all 0.3s ease 0s;
          -o-transition: all 0.3s ease 0s;
          transition: all 0.3s ease 0s;
          text-align: center;
        }
      }

      &:hover {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2);
      }
    }
    .btn-success {
      border-radius: 25px;
      font-weight: 600;
      text-decoration: none;
    }
    @media (max-width: 1440px) {
      padding: 30px 0 25px;
    }
    @media (max-width: 991px) {
      padding: 30px 0;
    }
    @media (max-width: 576px) {
      h2.title {
        font-size: clamp(1.3rem, 3vw, 1.8rem);
      }
      .mini-title {
        font-size: 0.9rem;
      }
    }
  }
  .section-5 {
    padding: 00px 15px 0;
    margin-top: 50px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .section-6 {
    padding: 0 15px 0px;
    margin-bottom: 50px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .box-d-w {
    padding: 2rem;
    .title {
      font-size: clamp(1.2rem, 3vw, 1.8rem);
      font-weight: 600;
      line-height: 120%;
      margin-bottom: 15px;
    }
    .content {
      font-size: 1.125rem;
      margin-bottom: 20px;
      font-weight: 400;
      text-align: justify;
    }
    .button-div {
      a {
        border-radius: 25px;
      }
    }
    @media (max-width: 1440px) {
      padding: 1rem;
      min-height: 270px;
      .title {
        font-size: clamp(1.1rem, 3vw, 1.6rem);
      }
      .content {
        font-size: 1rem;
        font-weight: 500;
      }
    }
    @media (max-width: 576px) {
      min-height: unset;
      .title {
        font-size: clamp(1.3rem, 3vw, 1.8rem);
      }
      .content {
        font-size: 1rem;
        font-weight: 500;
      }
    }
  }
  .section-7 {
    padding: 0px 0 1px;
  }
  .bg-od {
    background-color: #f2f4f6;
  }
  @keyframes leftrightborder {
    0% {
      border-top: 3px solid #0d9881;
      border-bottom: 2px solid #88c24b;
      border-right: 0px solid #0d9881;
      border-left: 0px solid #88c24b;
    }
    100% {
      // border-top: 0px solid #0d9881;
      // border-bottom: 0px solid #88c24b;
      border-right: 3px solid #0d9881;
      border-left: 3px solid #88c24b;
    }
  }
}
</style>
